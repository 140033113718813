import { all, call } from 'redux-saga/effects';
import {
	watchAudienceReachChange,
	searchProjectsSaga,
	watchGetAllProjectsData,
	watchChangeProjectName,
	watchSaveQuestion,
	watchDeleteQuestion,
	watchReOrderQuestionList,
	watchUpdateQuestion,
	watchCreateChoice,
	watchUpdateChoiceDebounce,
	watchDeleteChoice,
	watchBulkUpdateChoices,
	watchReorderChoiceList,
	watchQuestionnaireIsRandomizedChange,
	watchLoadProject,
	watchPostProjectFeedback,
	watchDeleteProjectFeedback,
	watchLoadPreviewStart,
	watchProjectFeasibilityCalculate,
	watchProjectCostCalculate,
	watchProjectCostRequestCalculate,
	watchSelectedAudienceCategoryRemoval,
	watchDeleteMatrixRow,
	watchCreateMatrixRow,
	watchUpdateMatrixChoiceTitle,
	watchCreateMatrixColumn,
	watchDeleteMatrixColumn,
	watchProjectCreation,
	watchLoadActivities,
	watchUpdateProjectStatus,
	watchAddPromoCode,
	watchDeletePromoCode,
	watchAddQuotaToAudience,
	watchDeleteQuotaFromAudience,
	watchUpdateQuotaField,
	watchAddDeleteQuotaField,
	watchLoadSubscription,
	watchLoadPromoCodes,
	watchQuestionListChanges,
	watchUpdateChoice,
} from './project';

import { watchLoadComments, watchPostComment } from './comments';
import {
	watchAddRule,
	watchUpdateRule,
	watchDeleteRule,
} from './project/logic';
import {
	watchCreateAddress,
	watchDeleteAddress,
	watchLoadAddresses,
	watchLoadCities,
	watchLoadDistricts,
	watchLoadCountries,
	watchUpdateAddress,
} from './addresses';
import { watchSignOut, watchProfileUpdate, watchVerifyEmail } from './auth';
import {
	watchAppliedFilterChange,
	watchLoadAnalysisData,
	watchFilterAndComparisonItemCountsChange,
	watchCrossTabSettingsChange,
	watchLoadQuestionnaireLanguages,
	watchLoadTranslatedQuestionTexts,
	watchLoadTranslatedQuestionTextsFinish,
	watchSetLanguage,
	watchAddResponseLabel,
	watchLoadLabelsStart,
	watchAddQuestionnaireLabel,
	watchDeleteLabel,
} from './project/Analysis';
import { watchUpdateNotificationSettings } from './userProfile';

//new sagas should be added here otherwise they will be ignored
function* rootSaga() {
	yield all([
		call(watchSignOut),
		call(watchProfileUpdate),
		call(watchVerifyEmail),

		call(watchAudienceReachChange),
		call(watchSelectedAudienceCategoryRemoval),

		call(watchProjectCreation),
		call(searchProjectsSaga),
		call(watchGetAllProjectsData),
		call(watchChangeProjectName),
		call(watchUpdateProjectStatus),
		call(watchSaveQuestion),
		call(watchDeleteQuestion),
		call(watchReOrderQuestionList),
		call(watchUpdateQuestion),
		call(watchQuestionListChanges),

		call(watchCreateChoice),
		call(watchUpdateChoice),
		call(watchUpdateChoiceDebounce),
		call(watchDeleteChoice),
		call(watchBulkUpdateChoices),
		call(watchReorderChoiceList),

		call(watchCreateMatrixRow),
		call(watchDeleteMatrixRow),
		call(watchUpdateMatrixChoiceTitle),
		call(watchCreateMatrixColumn),
		call(watchDeleteMatrixColumn),

		call(watchQuestionnaireIsRandomizedChange),
		call(watchLoadProject),
		call(watchLoadActivities),
		call(watchLoadComments),
		call(watchPostComment),
		call(watchLoadPreviewStart),

		call(watchAddRule),
		call(watchUpdateRule),
		call(watchDeleteRule),

		call(watchProjectCostCalculate),
		call(watchProjectCostRequestCalculate),
		call(watchProjectFeasibilityCalculate),
		call(watchAddPromoCode),
		call(watchLoadSubscription),
		call(watchDeletePromoCode),
		call(watchLoadPromoCodes),
		call(watchAddQuotaToAudience),
		call(watchDeleteQuotaFromAudience),
		call(watchUpdateQuotaField),
		call(watchAddDeleteQuotaField),

		call(watchPostProjectFeedback),
		call(watchDeleteProjectFeedback),

		call(watchLoadAddresses),
		call(watchCreateAddress),
		call(watchUpdateAddress),
		call(watchDeleteAddress),
		call(watchLoadCountries),
		call(watchLoadCities),
		call(watchLoadDistricts),

		call(watchLoadAnalysisData),
		call(watchAppliedFilterChange),
		call(watchFilterAndComparisonItemCountsChange),
		call(watchCrossTabSettingsChange),

		call(watchLoadQuestionnaireLanguages),
		call(watchLoadTranslatedQuestionTexts),
		call(watchLoadTranslatedQuestionTextsFinish),
		call(watchSetLanguage),

		call(watchUpdateNotificationSettings),
		call(watchAddResponseLabel),
		call(watchLoadLabelsStart),
		call(watchAddQuestionnaireLabel),
		call(watchDeleteLabel),
	]);
}

export default rootSaga;
