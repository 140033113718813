import { tidy, filter, select } from '@tidyjs/tidy';
import { indexOf } from 'lodash';
import { useEffect, useState } from 'react';
import { classNames, getQuestionTitle } from '../../utils';
import BarChart from './BarChart';
import Table2 from './Table2';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const findMaxNumber = dataArray => {
	let maxNumber = Number.NEGATIVE_INFINITY;

	dataArray.forEach(entry => {
		Object.keys(entry).forEach(key => {
			if (key !== 'country' && typeof entry[key] === 'number') {
				if (entry[key] > maxNumber) {
					maxNumber = entry[key];
				}
			}
		});
	});

	return maxNumber;
};

function calculateAverage(sampleData, rowId, columnId) {
	let total = 0;
	let count = 0;

	// Iterate through each row in the dataset
	for (const row of sampleData) {
		// Check if the given row exists in this object
		if (row[rowId] && row[rowId][columnId] !== undefined) {
			total += row[rowId][columnId]; // Add the column value to the total
			count++; // Increment the count
		}
	}

	// Calculate and return the average
	return count > 0 ? total / count : null; // Return null if no valid values are found
}

const heightMap = {
	1: 'h-[10rem]',
	2: 'h-[15rem]',
	3: 'h-[20rem]',
	4: 'h-[25rem]',
	5: 'h-[30rem]',
	6: 'h-[35rem]',
	7: 'h-[40rem]',
	8: 'h-[45rem]',
	9: 'h-[50rem]',
	10: 'h-[55rem]',
};

function MatrixChart({
	title = '% of all responses',
	data,
	question,
	translations,
	language,
	questionList,
}) {
	const { t } = useTranslation();
	const [di, setDi] = useState([]);
	const [tableRows, setTableRows] = useState([]);
	const keys = question.columns.map(column => column.nanoid);
	const { nanoid } = useParams();

	const labels = {};
	const columnHeads = [
		{ title: t('Row'), alignLeft: true },
		...question.columns.map(column => ({
			title: getQuestionTitle(
				column.title,
				questionList,
				translations,
				language,
				column.nanoid,
				true,
			),
		})),
	];

	if (nanoid === '1f3WJvW053eInEyR') {
		columnHeads.push({ title: t('Total'), alignLeft: true });
	}

	question.rows.forEach(row => {
		labels[row.nanoid] = getQuestionTitle(
			row.title,
			questionList,
			translations,
			language,
			row.nanoid,
			true,
		);

		question.columns.forEach(column => {
			labels[column.nanoid] = getQuestionTitle(
				column.title,
				questionList,
				translations,
				language,
				column.nanoid,
				true,
			);
		});
	});

	useEffect(() => {
		const _di = [];

		const filteredData = tidy(data, select(question.nanoid));
		const _tableRows = [];

		question.rows.forEach(row => {
			const responseData = {
				country: getQuestionTitle(
					row.title,
					questionList,
					translations,
					language,
					row.nanoid,
					true,
				),
			};

			const tableRow = [
				{
					value: getQuestionTitle(
						row.title,
						questionList,
						translations,
						language,
						row.nanoid,
						true,
					),
					alignLeft: true,
				},
			];

			if (
				!question.config['input_type'] ||
				question.config['input_type'] === 'selection'
			) {
				question.columns.forEach(column => {
					const c = tidy(
						filteredData,
						filter(d => {
							const rowChoices = tidy(
								d[question.nanoid],
								filter(r => r.choice === row.nanoid),
								filter(r => {
									const columnData = tidy(
										r.columns,
										filter(col => {
											return col.choice === column.nanoid;
										}),
									);

									return columnData.length > 0;
								}),
							);

							return rowChoices.length > 0;
						}),
					);

					responseData[column.nanoid] =
						filteredData.length > 0 ? c.length / filteredData.length : 0;

					tableRow.push({
						value: `${(responseData[column.nanoid] * 100).toFixed(2)}%`,
						numericValue: responseData[column.nanoid],
						mono: true,
					});
				});
				if (nanoid === '1f3WJvW053eInEyR') {
					const val = tableRow.reduce((acc, curr, index) => {
						if (index > 0) {
							acc = acc + curr.numericValue;
							return acc;
						}
						return acc;
					}, 0);

					tableRow.push({ value: `${(val * 100).toFixed(2)}%`, mono: true });
				}
			} else if (question.config['input_type'] === 'number') {
				question.columns.forEach(column => {
					const l = filteredData.map(data => data[question.nanoid]);
					const avg =
						filteredData.length > 0
							? calculateAverage(l, row.nanoid, column.nanoid)
							: 0;

					responseData[column.nanoid] = avg;

					tableRow.push({
						value: `${avg.toFixed(2)}`,
						mono: true,
					});
				});
			}

			_di.push(responseData);
			_tableRows.push(tableRow);
		});

		setDi(_di);
		setTableRows(_tableRows);
	}, [
		data,
		language,
		questionList,
		question.config,
		question.columns,
		question.nanoid,
		question.rows,
		translations,
		nanoid,
	]);

	const length = Math.round(Object.keys(labels).length / 2);

	return (
		<div>
			<div
				className={classNames(
					di.length > 0 ? heightMap[length] : heightMap[0],
					'mb-4',
				)}
			>
				{question.config['input_type'] &&
					question.config['input_type'] === 'number' && (
						<BarChart
							type={'absolute'}
							minValue={0}
							maxValue={Math.ceil(findMaxNumber(di))}
							tickValues={question.choices.length}
							groupMode={'grouped'}
							legend={t('analysis.questions.matrix.number.legend')}
							keys={keys}
							data={di}
							legendLabels={labels}
							forceShowLegend={true}
							isCompareMode={false}
						/>
					)}
				{(!question.config['input_type'] ||
					question.config['input_type'] === 'selection') && (
					<BarChart
						groupMode="grouped"
						legend={t(title)}
						keys={keys}
						data={di}
						legendLabels={labels}
						forceShowLegend={true}
						isCompareMode={false}
					/>
				)}
			</div>

			<Table2 columnHeads={columnHeads} rows={tableRows} />
		</div>
	);
}

export default function MatrixQuestion({
	data,
	question,
	labels,
	comparison,
	isCompareMode,
	translations,
	questionList,
	language,
}) {
	const matrices = [];

	if (isCompareMode) {
		Object.keys(comparison).forEach(comparisonKey => {
			Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
				const rankKeys = Object.keys(
					comparison[comparisonKey][comparisonItemKey],
				);
				const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
				rankArray.forEach(rankKey => {
					// TODO: Use analysisFilter util
					const comparisonResponses = tidy(
						data,
						filter(d => {
							const dataType = typeof d[comparisonKey];
							if (dataType === 'string' || dataType === 'number') {
								// For single choice filter items
								return d[comparisonKey] == comparisonItemKey;
							} else if (dataType === 'object') {
								// For multiple choice & ranking filter items
								const innerDataType =
									d[comparisonKey][0] == null
										? 'string'
										: typeof d[comparisonKey][0];

								if (innerDataType === 'string') {
									//for multiple choice
									return indexOf(d[comparisonKey], comparisonItemKey) > -1;
								} else if (innerDataType === 'object') {
									let foundItemIndex = d[comparisonKey].findIndex(
										answer =>
											answer.order == rankKey &&
											answer.choice === comparisonItemKey,
									);

									return foundItemIndex > -1;
								}
							}
						}),
					);

					const key =
						rankKey == -1
							? `${comparisonKey}_${comparisonItemKey}`
							: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;

					matrices.push(
						<div className="mb-8 px-4 pb-4 sm:px-6" key={key}>
							<MatrixChart
								title={`% of responses by ${labels[key]}`}
								data={comparisonResponses}
								question={question}
								translations={translations}
								language={language}
								questionList={questionList}
							/>
						</div>,
					);
				});
			});
		});
	}

	return (
		<div>
			{isCompareMode && (
				<div className="divide-y divide-gray-200">{matrices}</div>
			)}
			{!isCompareMode && (
				<div className="mb-8 px-4 pb-4 sm:px-6">
					<MatrixChart
						data={data}
						question={question}
						translations={translations}
						questionList={questionList}
						language={language}
					/>
				</div>
			)}
		</div>
	);
}
